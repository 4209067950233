function Card(props) {
  return (
    <div className="card">
      <br />
            
      <div className="text-center">
        <h2 className={`card-title ${props.service}`} >XAquo-{props.title}</h2>        
      </div>
      <div className="p-3">
        <p className={`card-text ${props.service}`}>
          {props.text}
        </p>
      </div>    
    {/* <div className="text-center">
      <img alt="card-img" src={"./img/"+props.img} className="text-center img-fluid" />
    </div> */}    
  </div>
    
  );
}
export default Card;
