import Typist from "react-typist";
import React, { useState, useEffect } from 'react';
function Header() {

  const [scaleFactor, setScaleFactor] = useState(1);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const newScaleFactor = 1 + (scrollY * 0.00005); // Adjust the scaling factor as needed
    setScaleFactor(newScaleFactor);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <header>
      <div className="row">
        <div className="text-container">
          <Typist>
            <span className="text-logo-x">X</span>
            <Typist.Delay ms={2500} />
            <span className="text-logo">AQUO</span>
            <Typist.Delay ms={1000} />
            <Typist count={13} delay={500} />
            <span className="text-logo-slogan">
              PRESERVING WATER, ELEVATING CROPS
            </span>
          </Typist>
        </div>
      </div>

      <div style={{width: '100%', textAlign: 'center'}} >
        <button className="getstarted-btn">
        <a className="getstarted-a" href="https://w.xaquo.com">GET STARTED</a>
          <i className="fas fa-arrow-right" style={{ marginLeft: "10px" }}></i>
        </button>
      </div>
      <div className="image-container">
      <img
        className="background-image"
        src="./img/macbook_image.webp"
        alt="Macbook"
        style={{ transform: `scale(${scaleFactor})` }}
      />
      <img
        className="overlay-image"
        src="./img/XAquo.png"
        alt="Overlay Image"
        
      />
    </div>
    </header>
  );
}
export default Header;
