import React from 'react';
import { useInView } from 'react-intersection-observer';
import Card from './Card'; 


const Services = () => {
  const [ref, inView] = useInView({
    triggerOnce: false, 
    threshold: 0.2,   
  });

  return (
    <div className="container services">
      <div className={`main-title-container ${inView ? 'title-services-visible' : 'title-services-hidden'}`}>
        <h2 className="main-title text-center">Products</h2>
    </div>
      <div className="card-cover">
        <div className="col-md-12">
          <div className="row">
            <div ref={ref} className={`col-md-4 card ${inView ? 'card-visible' : 'card-hidden'}`}>
              <Card 
                title="W" 
                service='w' 
                img="logo192.png" 
                text="A digital SaaS platform for the agriculture industry that controls
                water consumption using AI-driven irrigation systems while also
                sensoring weather conditions, crop health, and soil moisture.
                " />
            </div>
            <div ref={ref}  className={`col-md-4 card ${inView ? 'card-visible' : 'card-hidden'}`}>
              <Card 
                title="E" 
                service='e' 
                img="logo192.png" 
                text="Monitor real-time water consumption, compare it against historical data and benchmarks, and make analytic predictions of the irrigation water need to increase water efficiency." />
            </div>
          </div>    
        </div>
      </div>
    </div>
  );
};

export default Services;
